import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			imagesForModal: [],
		};
	},
	props: {
		showAllPara: false
	},
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {
			fields: this.resultFields,
			filter: {
				family: true
			},
			sorting: 'random',
			pricelist: true,
			max: 3
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

	},
	mounted: function () {
		var totalParagraph = $('#description').find('p').length;
		if (totalParagraph <= 1) {
			$("#showMoreLink").hide();
		}

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		showOffers: function (offers) {
			var result = false;
			if (offers) {

				for (var i = 0; i < offers.length; i++) {
					var offer = offers[i];
					if (!offer.voucherCode || offer.voucherCode === this.getVoucherCode()) {
						result = true;
						break;
					}
				}
			}

			return result;

		},
		showAllDescription: function () {
			this.showAllPara = true;
			$("#description").addClass('show-all');
		},
		showImagesModal: function (images, uniqueId = '') {
			if (images && images.length > 1) {
				this.imagesForModal = images;
				//$('#unitImages').modal('show');
				this.showCustomModal('unitImages' + uniqueId);

			} else {
				this.imagesForModal = [];
			}
		},
		showCustomModal: function (id) {
			let wh = window.innerHeight;

			// Get the modal
			const modal = document.getElementById(id);

			if (modal) {
				var dialog = modal.getElementsByClassName("modal-custom-dialog")[0];
				var content = modal.getElementsByClassName("modal-custom-content")[0];

				if (dialog && content) {
					dialog.style.height = wh - 40 + "px";
					content.style.height = wh - 120 + "px";

					modal.style.display = "block";

					// When the user clicks anywhere outside of the modal, close it
					window.onclick = function (event) {
						if (event.target == modal) {
							modal.style.display = "none";
						}
					};
				}
			}
		},

		closeCustomModal: function (id) {
			const modal = document.getElementById(id);
			if (modal) {
				modal.style.display = "none";
			}

		},
		getUnitSearchTarget: function (id) {
			return '_self';
			/*	if (window.screen.width < 768) {
					//probably phone?
					return '_self';
				} else {
					return 'unit_' + id;
				} */
		},

		myEventHandler(e) {
			//	console.log(window.innerWidth);
		}
	}

};