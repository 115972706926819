<template>
    <form class="input-group"
        @submit.prevent="goSearchUnit">
        <div class="form-obj-flex">
            <div class="form-flex-area">
                <input type="text"
                    v-model="unitname"
                    class="form-control"
                    placeholder="Objektname oder ID">
                <li aria-hidden="true"
                    class="fa fa-home"></li>
            </div>
        </div>
        <div class="form-btn">
            <div class="searchbox-obj">
                <button class="btn btn-primary "
                    type="submit">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </form>

</template>
<script>
    import {
    	isBlank
    }
    from 'voUtils/tools.js';
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';


    import {
    	doReq
    }
    from 'voUtils/RestEx.js';

    export default {

    	voVueComponent: 'search-unit-name',

    	data: function() {
    		return {
    			unitname: ''
    		}
    	},

    	methods: {
    		goSearchUnit: function() {
    			if (isBlank(this.unitname)) {
    				window.location.href = 's';
    			} else {
    				var req = {
    					fields: {
    						"u.path": {
    							"filter": "vlang"
    						}
    					},
    					filter: {
    						name: this.unitname
    					},
    					sorting: 'random',
    				};

    				doReq('searchUnits', req)
    					.then((res) => {
    						if (res.units.length === 1) {
    							window.location.href = './' + res.units[0].path;
    						} else {
    							window.location.href = 's#?name=' + encodeURIComponent(this.unitname);
    						}
    					});
    			}

    		}
    	}

    }
</script>