var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    !_vm.showText
      ? _c(
          "a",
          {
            attrs: {
              href: "javascript:void(0);",
              "data-bs-toggle": "popover",
              title: "Verfügbare Sonderangebote",
              "data-bs-trigger": "click",
              "data-bs-placement": "top",
              "data-bs-html": "true",
              "data-bs-content": _vm.getOfferText
            }
          },
          [
            _c("img", {
              staticClass: "offer-img",
              attrs: {
                src:
                  "https://r.v-office.com/preview/v1381/1707476242000/img/offers.svg",
                width: "40",
                height: "40",
                "data-toggle": "tooltip",
                title: _vm.getTitleText
              }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showText
      ? _c("span", [_vm._v("\n    " + _vm._s(_vm.getTitleText) + "\n")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }