var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "member",
      attrs: {
        "data-bs-toggle": "tooltip",
        "data-bs-html": "true",
        title: _vm.getTitle()
      }
    },
    [
      _c("img", {
        staticClass: "member-image",
        attrs: { "v-if": _vm.dataSrc, src: _vm.dataSrc }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "member-info" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n            " + _vm._s(_vm.name) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "resort" }, [
          _vm._v("\n            " + _vm._s(_vm.resort) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "phone" }, [
          _vm._v("\n            " + _vm._s(_vm.phone) + "\n        ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "email" }, [
          _vm._v("\n            " + _vm._s(_vm.email) + "\n        ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }