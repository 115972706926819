import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

$(function () {
	$("#telephone-box").on("click", function (a) {
		$("#customSelect").addClass("on");
		a.stopPropagation();
	});
	$(document).on("click", function (a) {
		if ($(a.target).is("#customSelect") === false) {
			$("#customSelect").removeClass("on");
		}
	});
});
$(document).ready(function () {
	$('.icon-menu').click(function () {
		$('.navbar-v1').toggleClass('show');
		$('.searchbar-v1').toggleClass('hide');
	});
});
export default {

	data: {
		name: undefined,
		showSearchBox: false,
		showTeleBox: false,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},

		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		},

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

	},

	methods: {
		scrollToTop: function () {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		toggleSearchbox: function (event) {
			this.showSearchBox = !this.showSearchBox;
			if (this.showSearchBox) {
				this.showTeleBox = false;
			}

		},
		toggleTelebox: function (event) {
			this.showTeleBox = !this.showTeleBox;
			if (this.showTeleBox) {
				this.showSearchBox = false;
			}

		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},

		navbarToggle: function () {


			$('.main-menu').toggleClass('active');
			$('.navbar-v1').removeClass('open-by-mob');

			this.showTeleBox = false;
			this.showSearchBox = false;


		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};