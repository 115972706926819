export default {
	data: {
		selectedImageIndex: undefined,
		openstreetmap: false,
		newsletteremail: ''
	},
	beforeMount: function () {

		const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
		var cookieName = 'allowOpenstreetmap_' + suffix;
		var allowOpenstreet = localStorage.getItem(cookieName);
		if (allowOpenstreet) {
			this.openstreetmap = true;
		}

		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;

	},
	mounted: function () {
		if ($(".tagdiv")) {
			var listForRemove = [];
			var listOfUniqe = [];
			$('.tagdiv li a').each(function () {
				var text = $(this).text().trim();
				if (listOfUniqe.indexOf(text) === -1)
					listOfUniqe.push(text);
				else
					listForRemove.push($(this).closest("li"));
			});

			$(listForRemove).each(function () {
				$(this).remove();
			});
		}

		var currenturl = window.location.href;
		//console.log('currenturl::' + currenturl);
		if (currenturl.indexOf("#") >= 0) {
			var sectionId = currenturl.substring(currenturl.indexOf("#") + 1);
			if (sectionId == 'wfv-zahlen') {

				$('html, body').animate({
					scrollTop: $("#wfvzahlen").offset().top - 100
				}, 5);

			}
		}
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['website', 'v-office.com']).join('@');
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		}
	},
	methods: {
		getNewsletterUrl: function () {
			return './newsletter#?email=' + this.newsletteremail;
		},
		setCookie: function (name) {
			localStorage.setItem(name, true);
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		unitImages: function (string) {
			//console.log(string);
		}
	},

};